var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"title":_vm.action ? '修改密码' : '设置密码',"isHeader":""}},[_c('div',{staticClass:"bind-form"},[_c('van-form',{ref:"changeForm",attrs:{"label-width":"2.2rem"}},[_c('van-field',{attrs:{"label":"手机号"}},[_c('template',{slot:"input"},[_c('phone',{attrs:{"phone":_vm.changeForm.oldPhone || _vm.cardInfo.phone}})],1)],2),_c('van-field',{attrs:{"rules":[{ required: true, message: '请输入验证码' }],"name":"验证码","type":"number","clearable":"","label":"验证码","maxlength":"6","placeholder":"请输入验证码","autocomplete":"off"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('MsgCode',{attrs:{"phone":_vm.changeForm.oldPhone || _vm.cardInfo.phone,"isLine":"","type":"MEMBER_CARD_UPDATE_PASSWORD"}})]},proxy:true}]),model:{value:(_vm.changeForm.vcode),callback:function ($$v) {_vm.$set(_vm.changeForm, "vcode", $$v)},expression:"changeForm.vcode"}}),_c('van-field',{attrs:{"rules":[
          { required: true, message: '请输入六位新密码' },
          {
            validator: _vm.pwdValidator,
            message: '密码过于简单，请重新设置',
            trigger: 'onChange',
          },
          {
            validator: _vm.limitSix,
            message: '请输入六位新密码',
            trigger: 'onChange',
          },
        ],"readonly":"","name":"新密码","autocomplete":"off","type":"password","clearable":"","label":"新密码","placeholder":"请输入六位新密码"},on:{"touchstart":function($event){$event.stopPropagation();_vm.show = true}},model:{value:(_vm.changeForm.cardPassword),callback:function ($$v) {_vm.$set(_vm.changeForm, "cardPassword", $$v)},expression:"changeForm.cardPassword"}}),_c('van-number-keyboard',{attrs:{"show":_vm.show,"maxlength":6},on:{"blur":function($event){_vm.show = false}},model:{value:(_vm.changeForm.cardPassword),callback:function ($$v) {_vm.$set(_vm.changeForm, "cardPassword", $$v)},expression:"changeForm.cardPassword"}}),_c('van-field',{attrs:{"rules":[
          { required: true, message: '请再次输入六位密码' },
          {
            validator: _vm.pwdValidator,
            message: '密码过于简单，请重新设置',
            trigger: 'onChange',
          },
          {
            validator: _vm.pwdSameValidator,
            message: '密码不一致，请重新输入',
            trigger: 'onChange',
          },
          {
            validator: _vm.limitSix,
            message: '请输入六位新密码',
            trigger: 'onChange',
          },
        ],"readonly":"","name":"密码确认","autocomplete":"off","type":"password","clearable":"","label":"密码确认","placeholder":"请再次输入六位密码"},on:{"touchstart":function($event){$event.stopPropagation();_vm.repeatShow = true}},model:{value:(_vm.changeForm.conPassword),callback:function ($$v) {_vm.$set(_vm.changeForm, "conPassword", $$v)},expression:"changeForm.conPassword"}}),_c('van-number-keyboard',{attrs:{"show":_vm.repeatShow,"maxlength":6},on:{"blur":function($event){_vm.repeatShow = false}},model:{value:(_vm.changeForm.conPassword),callback:function ($$v) {_vm.$set(_vm.changeForm, "conPassword", $$v)},expression:"changeForm.conPassword"}})],1)],1),(_vm.cardInfo.hasPassword !== 1)?_c('div',{staticClass:"lk-p-12 lk-p-t-16 lk-font-sm tip-color"},[_vm._v(" * 您为首次设置密码 ")]):_vm._e(),_c('div',{staticClass:"content"},[_c('van-button',{staticClass:"lk-m-t-40",attrs:{"color":"#F8B500","loading":_vm.loading,"block":"","round":""},on:{"click":_vm.handleClickChange}},[_vm._v(_vm._s(_vm.action ? '确定修改' : '确定'))])],1),_c('Dialog',{attrs:{"visible":_vm.showErrMessage,"theme":"round-button","showCancelButton":false,"content":_vm.errMessage},on:{"update:visible":function($event){_vm.showErrMessage=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }