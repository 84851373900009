var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"isHeader":""}},[_c('div',{staticClass:"limit-form"},[_c('van-form',{ref:"limitForm"},[_c('van-field',{attrs:{"rules":[
          { validator: _vm.validatorPrice, message: '金额输入有误' }
        ],"name":"单日金额限制","type":"number","clearable":"","label":"单日金额限制","placeholder":"选填,最大不超过1000","autocomplete":"off","maxlength":"7"},model:{value:(_vm.limitForm.singleDayLimit),callback:function ($$v) {_vm.$set(_vm.limitForm, "singleDayLimit", $$v)},expression:"limitForm.singleDayLimit"}}),_c('van-field',{attrs:{"rules":[
          { validator: _vm.validatorPrice, message: '金额输入有误' }
        ],"name":"单笔金额限制","type":"number","clearable":"","label":"单笔金额限制","placeholder":"选填,最大不超过1000","autocomplete":"off","maxlength":"7"},model:{value:(_vm.limitForm.singleOrderLimit),callback:function ($$v) {_vm.$set(_vm.limitForm, "singleOrderLimit", $$v)},expression:"limitForm.singleOrderLimit"}}),_c('van-field',{attrs:{"rules":[
          { validator: _vm.validatorOrderNum, message: '请输入0-99内正整数' }
        ],"name":"单日笔数限制","type":"digit","clearable":"","label":"单日笔数限制","placeholder":"选填,最大不超过100","autocomplete":"off","maxlength":"3"},model:{value:(_vm.limitForm.orderNumLimit),callback:function ($$v) {_vm.$set(_vm.limitForm, "orderNumLimit", $$v)},expression:"limitForm.orderNumLimit"}})],1)],1),_c('div',{staticClass:"content"},[_c('p',{staticClass:"lk-m-t-16 font-3 lk-font-14"},[_vm._v("数值为0或者为空，无限制")]),_c('van-button',{staticClass:"lk-m-t-40 font-1",attrs:{"color":"#F8B500","block":"","round":""},on:{"click":_vm.handleClickSave}},[_vm._v("保存")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }