<template>
  <Page :title="action ? '修改密码' : '设置密码'" isHeader>
    <div class="bind-form">
      <van-form label-width="2.2rem" ref="changeForm">
        <van-field label="手机号">
          <template slot="input">
            <!-- <phone :phone="$route.query.phone || cardInfo.phone" /> -->
            <phone :phone="changeForm.oldPhone || cardInfo.phone" />
          </template>
        </van-field>
        <van-field
          v-model="changeForm.vcode"
          :rules="[{ required: true, message: '请输入验证码' }]"
          name="验证码"
          type="number"
          clearable
          label="验证码"
          maxlength="6"
          placeholder="请输入验证码"
          autocomplete="off"
        >
          <template #button>
            <MsgCode
              :phone="changeForm.oldPhone || cardInfo.phone"
              isLine
              type="MEMBER_CARD_UPDATE_PASSWORD"
            />
          </template>
        </van-field>
        <van-field
          v-model="changeForm.cardPassword"
          :rules="[
            { required: true, message: '请输入六位新密码' },
            {
              validator: pwdValidator,
              message: '密码过于简单，请重新设置',
              trigger: 'onChange',
            },
            {
              validator: limitSix,
              message: '请输入六位新密码',
              trigger: 'onChange',
            },
          ]"
          readonly
          name="新密码"
          autocomplete="off"
          type="password"
          clearable
          label="新密码"
          placeholder="请输入六位新密码"
          @touchstart.stop="show = true"
        />
        <van-number-keyboard
          v-model="changeForm.cardPassword"
          :show="show"
          :maxlength="6"
          @blur="show = false"
        />
        <van-field
          v-model="changeForm.conPassword"
          :rules="[
            { required: true, message: '请再次输入六位密码' },
            {
              validator: pwdValidator,
              message: '密码过于简单，请重新设置',
              trigger: 'onChange',
            },
            {
              validator: pwdSameValidator,
              message: '密码不一致，请重新输入',
              trigger: 'onChange',
            },
            {
              validator: limitSix,
              message: '请输入六位新密码',
              trigger: 'onChange',
            },
          ]"
          readonly
          name="密码确认"
          autocomplete="off"
          type="password"
          clearable
          label="密码确认"
          placeholder="请再次输入六位密码"
          @touchstart.stop="repeatShow = true"
        />
        <van-number-keyboard
          v-model="changeForm.conPassword"
          :show="repeatShow"
          :maxlength="6"
          @blur="repeatShow = false"
        />
      </van-form>
    </div>
    <div
      v-if="cardInfo.hasPassword !== 1"
      class="lk-p-12 lk-p-t-16 lk-font-sm tip-color"
    >
      * 您为首次设置密码
    </div>
    <div class="content">
      <van-button
        class="lk-m-t-40"
        color="#F8B500"
        :loading="loading"
        block
        round
        @click="handleClickChange"
        >{{ action ? '确定修改' : '确定' }}</van-button
      >
    </div>
    <Dialog
      :visible.sync="showErrMessage"
      theme="round-button"
      :showCancelButton="false"
      :content="errMessage"
    />
  </Page>
</template>

<script>
import MsgCode from '@/components/Msgcode.vue';
import Phone from '@/components/Phone.vue';
import { openCardlessPaymentApi } from '@/api/card';
import { cipherPassword } from '@/utils/format';

import { mapState } from 'vuex';
export default {
  name: 'Password',
  components: {
    MsgCode,
    Phone,
  },
  data() {
    const pwdValidator = (val) => {
      return (
        !/(\d)\1{3}/.test(val) || !/^(?=\d{6}$)1*2*3*4*5*6*7*8*9*0*$/.test(val)
      );
    };

    const pwdSameValidator = (val) => {
      if (val.length == 6) {
        return (
          this.changeForm.cardPassword && val === this.changeForm.cardPassword
        );
      }
    };

    const limitSix = (val) => {
      return val.length === 6;
    };

    return {
      showErrMessage: false,
      errMessage: '',
      pwdType: 'password',
      confirmPwdType: 'password',
      action: '',
      openState: 0,
      loading: false,
      show: false,
      repeatShow: false,
      patternPhone: /^1[3456789]\d{9}$/,
      changeForm: {
        vcode: '',
        cardPassword: '',
        conPassword: '',
      },
      hasBindInfo: {},
      pwdValidator,
      pwdSameValidator,
      limitSix,
    };
  },
  computed: {
    ...mapState('entery', ['Info']),
    ...mapState('card', ['cardInfo']),
  },
  beforeRouteEnter(to, from, next) {
    // ...
    next((vm) => {
      vm.$refs.changeForm.resetValidation();
      vm.changeForm = {
        vcode: '',
        cardPassword: '',
        conPassword: '',
      };
      let { action, openState } = to.query || {};
      vm.action = action;
      vm.openState = openState;
    });
  },
  methods: {
    async handleClickChange() {
      let that = this;
      this.$refs.changeForm
        .validate()
        .then(async () => {
          try {
            // ToDO：开通无卡支付接口
            const { cardPassword, conPassword, vcode } = this.changeForm;
            await openCardlessPaymentApi({
              cardNo: this.cardInfo.cardNo,
              openState: Number(this.openState),
              vcode,
              cardPassword: cipherPassword(this.cardInfo.phone, cardPassword),
              conPassword: cipherPassword(this.cardInfo.phone, conPassword),
            });

            that.$toast(this.action ? '修改成功' : '设置成功');
            this.$router.push({
              name: 'User',
            });
          } catch (error) {
            this.showErrMessage = true;
            this.errMessage = error.message;
          }
        })
        .catch(() => {
          that.$toast('信息有误，请检查');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bind-form {
  background-color: #ffffff;
}
.tip-color {
  color: #f8b500;
}
</style>
