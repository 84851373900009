<template>
  <Page isHeader>
    <div class="lk-font-16 font-1 lk-bg-white">
      <div class="lk-p-t-24">
        <div class="lk-flex lk-row-center">
          <van-image
            width="200"
            height="136"
            :src="$baseImg + 'GOODS1702368219944'"
          />
        </div>
        <div class="lk-text-center font-2 lk-font-sm lk-p-t-14">
          <div class="lk-p-b-14">开通密码支付</div>
          <div class="lk-p-b-14">在售货机屏幕输入手机号和密码即可支付</div>
          <div class="lk-p-b-14">为保护您的余额安全，请妥善保管您的密码</div>
          <!-- 开通无卡支付后，在售货机上输入手机号后四位和密码即可支付
          ，为保护您的余额安全，请妥善保管您的密码 -->
        </div>
      </div>
      <div class="lk-p-12 lk-border-bottom">
        <div class="lk-flex lk-row-between">
          <div>密码支付</div>
          <div>
            <van-switch
              v-model="checked"
              :active-value="1"
              :inactive-value="0"
              :loading="loading"
              active-color="#F8B500"
              inactive-color="#eee"
              size="22px"
              @change="changeSwitch"
            />
          </div>
        </div>
      </div>
      <van-cell
        v-if="cardInfo.hasPassword === 1"
        center
        is-link
        :title-style="{
          padding: '1px 0',
        }"
        @click="handleEditPassword('1')"
      >
        <!-- <template #icon>
            <i class="icon iconfont lk-font-24" :class="[menu.icon]" />
          </template> -->
        <template #title>
          <div class="lk-font-md">修改密码</div>
        </template>
      </van-cell>
    </div>

    <Dialog
      :visible.sync="showDialog"
      content="首次开通无卡支付需设置密码"
      confirmButtonText="设置密码"
      cancelButtonText="取消开通"
      @cancel="checked = 0"
      @confirm="goPassword"
    />
  </Page>
</template>

<script>
import { mapState } from 'vuex';
import { openCardlessPaymentApi } from '@/api/card';
export default {
  name: 'CardlessPayment',
  data() {
    return {
      checked: 0,
      loading: false,
      showDialog: false,
    };
  },
  computed: {
    ...mapState('card', ['cardInfo']),
  },
  watch: {
    'cardInfo.openState': {
      handler(n) {
        this.checked = n === null ? 0 : Number(n);
        // this.originChecked=this.checked
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async changeSwitch(val) {
      // console.log('val==>', val);

      if (this.cardInfo.hasPassword !== 1) {
        // 首次开通才需要提示
        this.showDialog = true;
      } else {
        // ToDO：开通无卡支付接口（changeState==1 只改状态）
        this.checked = val === 1 ? 0 : 1; // 接口调用之前保持原状态
        this.loading = true;
        const { message } = await openCardlessPaymentApi({
          cardNo: this.cardInfo.cardNo,
          openState: Math.pow(0, Number(this.checked)),
          changeState: 1,
        });
        this.loading = false;
        this.checked = Math.pow(0, Number(this.checked));
        this.$toast(message);
      }
    },
    handleEditPassword(action) {
      // console.log('checked==>', this.checked);
      this.$router.push({
        name: 'Password',
        query: {
          action: action,
          openState: this.checked,
        },
      });
    },
    goPassword() {
      this.showDialog = false;
      this.checked = 1;
      this.handleEditPassword('');
    },
  },
};
</script>

<style lang="scss" scoped></style>
